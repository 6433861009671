import React from "react";

// reactstrap components
import { UncontrolledCarousel} from "reactstrap";
const carouselItems = [
    {
        src: require("assets/img/17.webp"),
        altText: "Dreams are real",
        caption: "",
        header: ""
    }
];
class DreamsHeader extends React.Component {
    render() {
        return (
            <div className="page-header">
                <UncontrolledCarousel
                    id={"headSlider"}
                    items={carouselItems}
                    indicators={false}
                    autoPlay={false}
                />
                <div className="carousel-two-captions">
                    <h3>Dreams are real</h3>
                    <p id="caption1">Wszystko zaczyna się z pomysłu</p>
                    <p id="caption2">Odbądż spotkanie online i zarezerwuj termin prac bez wychodzenia z domu</p>
                </div>
            </div>
        );
    }
}

export default DreamsHeader;
