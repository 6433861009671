import React from "react";

// reactstrap components
import {Button, Card, Col, Container, Row, UncontrolledCarousel} from "reactstrap";
const uslugiItems = [
    {
        src: require("assets/img/16.webp"),
        altText: "pokój4",
        caption: ""
    },
    {
        src: require("assets/img/1.webp"),
        altText: "pokój1",
        caption: ""
    },
    {
        src: require("assets/img/4.webp"),
        altText: "pokój2",
        caption: ""
    },
    {
        src: require("assets/img/8-1.webp"),
        altText: "pokój3",
        caption: ""
    }
];
class DreamsUslugi extends React.Component {
    getPageScroll = () => {
        let yScroll;

        if (window.pageYOffset) {
            yScroll = window.pageYOffset;
        } else if (document.documentElement && document.documentElement.scrollTop) {
            yScroll = document.documentElement.scrollTop;
        } else if (document.body) {
            yScroll = document.body.scrollTop;
        }
        return yScroll;
    };

    scrollPage = (element) => {
        let targetOffset = element.offsetTop;
        let currentPosition = this.getPageScroll();
        let body = document.body;
        let animateTime = 600;
        body.classList.add('in-transition');
        if(targetOffset > currentPosition) {
            body.style.transform = "translate(0, -" + (targetOffset - 65 - currentPosition) + "px)";
        } else {
            body.style.transform = "translate(0, " + (-targetOffset + 65 + currentPosition) + "px)";
        }
        window.setTimeout(function () {
            body.classList.remove('in-transition');
            body.style.cssText = "";
            console.log(targetOffset);
            window.scrollTo(0, targetOffset - 65);
        }, animateTime);
    };
    scrollToKontakt = () => {
        this.scrollPage(document.getElementById('kontakt'));
    };
    render() {
        return (
            <Card id={"uslugi"}>
                <Container className="align-items-center">
                    <Row>
                        <Col lg="6" md="6">
                            <h1 className="profile-title text-left-132 text-blue">Usługi <b>złotej rączki</b></h1>
                            <h5 className="text-on-back text-blue-opacity">03</h5>
                            <p className="profile-description">
                                Dwoma głównymi obszarami usług świadczonych przez nas w tym zakresie są montaż i naprawa. Regulacja drzwi, okien, montaż szafek dowolnej zlożoności, usuwanie defektów na ścianach, wymiana pękniętej glazury - to przykładowe zlecenia, których podejmiemy się. Ponadto jesteszmy w stanie niekiedy wyręczyć innego fachowca, zwłaszcza przy drobnych naprawach. Możemy naprawić cieknący kran, zepsutą baterię łazienkową, wymienić lub przesunąć gniazdo albo włącnik a także wiele innych usług.
                            </p>
                            <div className="btn-wrapper profile pt-3">
                                <Button className="btn-simple btn-round"
                                        color="primary"
                                        type="button"
                                        onClick={this.scrollToKontakt}
                                >
                                    <i className="tim-icons icon-mobile" />
                                    &nbsp;&nbsp;Umów się z nami na spotkanie
                                </Button>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto mt-30" lg="6" md="6" style={{height:"500px"}}>
                            <UncontrolledCarousel items={uslugiItems} />
                        </Col>
                    </Row>
                </Container>
            </Card>
        );
    }
}

export default DreamsUslugi;