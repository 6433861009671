import React from "react";
import { Link } from "react-router-dom";
import pdf from "../../assets/files/regulamin.pdf"
// reactstrap components
import {
    NavItem,
    Nav,
    Container,
    Row,
    Col, Button, NavbarBrand
} from "reactstrap";

class Footer extends React.Component {
    getPageScroll = () => {
        let yScroll;

        if (window.pageYOffset) {
            yScroll = window.pageYOffset;
        } else if (document.documentElement && document.documentElement.scrollTop) {
            yScroll = document.documentElement.scrollTop;
        } else if (document.body) {
            yScroll = document.body.scrollTop;
        }
        return yScroll;
    };

    scrollPage = (element) => {
        let targetOffset = element.offsetTop;
        let currentPosition = this.getPageScroll();
        let body = document.body;
        let animateTime = 600;
        body.classList.add('in-transition');
        if(targetOffset > currentPosition) {
            body.style.transform = "translate(0, -" + (targetOffset - 65 - currentPosition) + "px)";
        } else {
            body.style.transform = "translate(0, " + (-targetOffset + 65 + currentPosition) + "px)";
        }
        window.setTimeout(function () {
            body.classList.remove('in-transition');
            body.style.cssText = "";
            console.log(targetOffset);
            window.scrollTo(0, targetOffset - 65);
        }, animateTime);
    };
    scrollToMeble = () => {
        this.scrollPage(document.getElementById('meble'));
    };
    scrollToWykonczenie = () => {
        this.scrollPage(document.getElementById('wykonczenie'));
    };
    scrollToUslugi = () => {
        this.scrollPage(document.getElementById('uslugi'));
    };
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row className={"justify-content-center"}>
            <Col md="4">
                <NavbarBrand
                    to="/"
                    id="navbar-brand"
                    tag={Link}
                >
                    <img className="brand-logo"
                         src={require("assets/img/brand-transparent-white.webp")}
                         alt="Dreams are real" />
                    <span className={"text-dark"}/>{/*В спане можно ввести название, которое появится рядом с логотипом*/}
                </NavbarBrand>
                <h3>Krótko o nas</h3>
                <p>Jesteszmy fachowcami w realizacji twoich marzeń - wykończenie całego mieszkania lub naprawa drobnych
                    usterek, a może przyszed czas na nową zabudowę meblową? Każde zamówienie jest realizowane przez mistrzów
                    swojej sprawy, uwzględniając nawet najmniejszy pomysł klientów.</p>
            </Col>
            <Col md="2">
                <h3 className={"margin10"}>Menu</h3>
                <hr className={"hr-footer"}/>
              <Nav>
                  <NavItem>
                      <Button
                          className="btn-simple btn-round btn-navbar"
                          type="button"
                          onClick={this.scrollToMeble}
                      >
                          &nbsp;Zabudowy meblowe&nbsp;
                      </Button>
                  </NavItem>
                  <NavItem>
                      <Button
                          className="btn-simple btn-round btn-navbar"
                          type="button"
                          onClick={this.scrollToWykonczenie}
                      >
                          &nbsp;Wykończenie mieszkań&nbsp;
                      </Button>
                  </NavItem>
                  <NavItem>
                      <Button
                          className="btn-simple btn-round btn-navbar"
                          type="button"
                          onClick={this.scrollToUslugi}
                      >
                          &nbsp;Usługi złotej rączki&nbsp;
                      </Button>
                  </NavItem>
                  <NavItem>
                      <Button
                          className="btn-simple btn-round btn-navbar"
                          type="button"
                          href={pdf}
                      >
                          &nbsp;Regulamin&nbsp;
                      </Button>
                  </NavItem>
              </Nav>
            </Col>
            <Col md="2" className={"margint20"}>
                  <div className="icon icon-primary display-inline">
                      <i className="tim-icons icon-mobile icon-rem-2" />
                  </div>
                  <div className="description-1 display-inline">
                      <h4 className="info-title display-inline">Zadzwoń</h4>
                      <p className={"description-3"}>
                          +48731493298<br/>
                          +48739681222
                      </p>
                  </div>
                  <div className="icon icon-primary display-inline">
                      <i className="tim-icons icon-email-85 icon-rem-2" />
                  </div>
                  <div className="description-1 display-inline">
                      <h4 className="info-title display-inline">Napisz</h4>
                      <p className={"description-3"}>
                          kontakt@dreamsarereal.pl
                      </p>
                  </div>
                  <div className="icon icon-primary display-inline">
                      <i className="tim-icons icon-watch-time icon-rem-2" />
                  </div>
                  <div className="description-1 display-inline">
                      <h4 className="info-title display-inline">Godziny pracy</h4>
                      <p className={"description-3"}>
                          pon-pt 8:00-16:00
                      </p>
                  </div>
              </Col>
            <Col md={"3 margint20"}>
                  <h3 className={"description-5"}>My na innych platformach</h3>
                  <hr className={"hr-footer-2"}/>
                  <div className="links">
                      <a data-placement="bottom" href="https://kb.pl/firmy/dreams-are-real-sp-z-o-o/" rel="noopener noreferrer" target="_blank" title="kb.pl" className="nav-link footer-nav-link">
                          <i className="kbpl"/>
                      </a>
                      <a data-placement="bottom" href="https://dreamsarereal.oferteo.pl/" rel="noopener noreferrer" target="_blank" title="oferteo.pl" className="nav-link footer-nav-link">
                          <i className="oferteo"/>
                      </a>
                      <a  data-placement="bottom" href="https://www.needhelp.com/pl-pl/user/693708" rel="noopener noreferrer" target="_blank" title="needhelp.com" className="nav-link footer-nav-link">
                          <i className="needhelp"/>
                      </a>
                  </div>
                  <p className={"description-3"}>
                      Spodobała się nasza praca? Napisz o nas opinię na innych platformach lub pochwał się zdjęciami realizacji.
                  </p>
              </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
