import React from "react";

// core components
import DreamsNavbar2 from "../../components/Navbars/DreamsNavbar2";
import DreamsHeader from "../../components/PageHeader/DreamsHeader";
import Footer from "../../components/Footer/Footer.js";
import DreamsMeble from "../IndexSections/DreamsMeble";
import DreamsWykonczenie from "../IndexSections/DreamsWykonczenie";
import DreamsUslugi from "../IndexSections/DreamsUslugi";
import DreamsKontakt from "../IndexSections/DreamsKontakt";



class DreamsPage extends React.Component {
    componentDidMount() {
        document.body.classList.toggle("dreams-page");
    }
    componentWillUnmount() {
        document.body.classList.toggle("dreams-page");
    }
    render() {
        return (
            <>
                <DreamsNavbar2 />
                <div className="wrapper">
                    <DreamsHeader/>
                    <DreamsMeble/>
                    <DreamsWykonczenie/>
                    <DreamsUslugi/>
                    <DreamsKontakt/>
                    <Footer />
                </div>
            </>
        );
    }
}

export default DreamsPage;
