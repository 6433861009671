import React from "react";
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input, Col, Row
} from "reactstrap";

// core components
import * as emailjs from "emailjs-com";
import classnames from "classnames";

class DreamsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { feedback: '', name: '', email: '', tnumer: '' };
        this.handleFeedback = this.handleFeedback.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTnumer = this.handleTnumer.bind(this);
    }
    handleFeedback(event) {
        this.setState({feedback: event.target.value})
    }
    handleName(event) {
        this.setState({name: event.target.value})
    }
    handleEmail(event) {
        this.setState({email: event.target.value})
    }
    handleTnumer(event) {
        this.setState({tnumer: event.target.value})
    }
    sendFeedback (templateId, variables) {
        emailjs.init("user_Gib53zUo4CNVmPhxMqbFf");
        emailjs.send(
            'smtp_server', templateId,
            variables
        ).then(res => {
            console.log('Email successfully sent!')
        })
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
    handleSubmit (event) {
        const templateId = 'template_m9NR2lqs';
        this.sendFeedback(templateId, {message_html: this.state.feedback, from_name: this.state.name, reply_to: this.state.email, phone_num: this.state.tnumer})
    }
    render(){
        return (
            <>
                <Row className="row-grid justify-content-space-around align-items-center">
                    <Col lg={"6"}>
                <form id="email">
                    <div>
                        <InputGroup
                            className={classnames({
                                "input-group-focus": this.state.inputFocus
                            })}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-user" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Imię"
                                type="text"
                                value={this.state.name}
                                onFocus={e => this.setState({ inputFocus: true })}
                                onBlur={e => this.setState({ inputFocus: false })}
                                onChange = {this.handleName}
                            />
                        </InputGroup>
                        <InputGroup
                            className={classnames({
                                "input-group-focus": this.state.inputFocus
                            })}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="tim-icons icon-email-85" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Email"
                                type="text"
                                value={this.state.email}
                                onFocus={e => this.setState({ inputFocus: true })}
                                onBlur={e => this.setState({ inputFocus: false })}
                                onChange = {this.handleEmail}
                            />
                        </InputGroup>
                        <InputGroup
                            className={classnames({
                                "input-group-focus": this.state.inputFocus
                            })}
                        >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="tim-icons icon-mobile" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Nr. telefonu"
                                type="text"
                                value={this.state.tnumer}
                                onFocus={e => this.setState({ inputFocus: true })}
                                onBlur={e => this.setState({ inputFocus: false })}
                                onChange = {this.handleTnumer}
                            />
                        </InputGroup>
                        <textarea
                            id="test-mailing"
                            name="test-mailing"
                            onChange={this.handleFeedback}
                            placeholder="Jestem zainteresowany ofertą. Proszę o kontakt"
                            required
                            value={this.state.feedback}
                            style={{width: '100%', height: '150px'}}
                        />
                    </div>
                    <input type="button" value="Wyślij" className="btn btn--submit" onClick={this.handleSubmit} />
                </form>
                    </Col>
                    <Col lg="6">
                        <div>
                            Dreams Are Real Sp.z o.o. <br/><br/>

                            Zadzwoń i umów się na spotkanie lub rozmowę online: <br/>
                            <i className="fa fa-user"/>&nbsp;&nbsp;Serhii Drahanchuk<br/>
                            <i className="tim-icons icon-mobile"/>&nbsp;&nbsp;+48731493298<br/>
                            <i className="tim-icons icon-email-85"/>&nbsp;&nbsp;kontakt@dreamsarereal.pl<br/>
                            <i className="tim-icons icon-square-pin"/>&nbsp;&nbsp;Aleje Jerozolimskie 85, lok. 21<br/><br/>

                            Bieżące zamówienia:<br/>
                            <i className="fa fa-user"/>&nbsp;&nbsp;Ruslan Drahanchuk<br/>
                            <i className="tim-icons icon-mobile"/>&nbsp;&nbsp;+48739681222<br/>
                            <i className="tim-icons icon-email-85"/>&nbsp;&nbsp;orders@dreamsarereal.pl<br/>
                        </div>
                    </Col>
                </Row>
                </>

        );
    }
}
export default DreamsModal;