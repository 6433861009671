import React from "react";

// reactstrap components
import {Button, Col, Container, Row, UncontrolledCarousel} from "reactstrap";
const wykonczenieItems = [
    {
        src: require("assets/img/2.webp"),
        altText: "pokój1",
        caption: ""
    },
    {
        src: require("assets/img/3-1.webp"),
        altText: "pokój2",
        caption: ""
    },
    {
        src: require("assets/img/3-2.webp"),
        altText: "pokój3",
        caption: ""
    },
    {
        src: require("assets/img/9.webp"),
        altText: "pokój4",
        caption: ""
    }
];
class DreamsWykonczenie extends React.Component {
    getPageScroll = () => {
        let yScroll;

        if (window.pageYOffset) {
            yScroll = window.pageYOffset;
        } else if (document.documentElement && document.documentElement.scrollTop) {
            yScroll = document.documentElement.scrollTop;
        } else if (document.body) {
            yScroll = document.body.scrollTop;
        }
        return yScroll;
    };

    scrollPage = (element) => {
        let targetOffset = element.offsetTop;
        let currentPosition = this.getPageScroll();
        let body = document.body;
        let animateTime = 600;
        body.classList.add('in-transition');
        if(targetOffset > currentPosition) {
            body.style.transform = "translate(0, -" + (targetOffset - 65 - currentPosition) + "px)";
        } else {
            body.style.transform = "translate(0, " + (-targetOffset + 65 + currentPosition) + "px)";
        }
        window.setTimeout(function () {
            body.classList.remove('in-transition');
            body.style.cssText = "";
            console.log(targetOffset);
            window.scrollTo(0, targetOffset - 65);
        }, animateTime);
    };
    scrollToKontakt = () => {
        this.scrollPage(document.getElementById('kontakt'));
    };
    render() {
        return (
            <div className="section" id={"wykonczenie"}>
                <Container>
                    <Row className="justify-content-between">
                        <Col md="6">
                            <Row className="justify-content-between align-items-center">
                                <UncontrolledCarousel items={wykonczenieItems} />
                            </Row>
                        </Col>
                        <Col md="5">
                            <h1 className="profile-title text-left-68 text-blue">Wykończenie mieszkań i domów <b>pod klucz</b></h1>
                            <h5 className="text-on-back text-blue-opacity">02</h5>
                            <p className="profile-description text-left">
                                Jesteśmy elastyczni i chętnie wsłuchujemy się w pomysły naszych klientów, które wychodzą poza schemat. Czerpiemy inspiracje z Waszych pomysłów! Poza standardową ofertą wykończenia wnętrz oferujemy także kompleksowe remonty. Nasz zespół składa się z doświadczonych pracowników, którzy z chęcią pomogą w urządzeniu mieszkania pod klucz. Każdy remont jest wykonywany przez nas z niezwykła dbałością o szczegóły, przez co realizujemy nawet najbardziej wymagające projekty. Terminowość, uczciwość oraz wysoka jakość przy zachowaniu konkurencyjnych cen to atuty jakie nas wyróżniają. W naszej ofercie dajemy możliwość na przywrócenie lokalu do stanu deweloperskiego, a następnie skorzystania naszej oferty wykończeniowej.
                            </p>
                            <div className="btn-wrapper pt-3">
                                <Button className="btn-simple btn-round"
                                        color="primary"
                                        type="button"
                                        onClick={this.scrollToKontakt}
                                >
                                    <i className="tim-icons icon-mobile" />
                                    &nbsp;&nbsp;Umów się z nami na spotkanie
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default DreamsWykonczenie;
