import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Modal,
    ModalBody, Button
} from "reactstrap";
import DreamsModal from "../../views/IndexSections/DreamsModal";

class DreamsNavbar2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseOpen: false,
            color: "bg-transparent",
            btntextcolor: "btn-nav-white",
            btnnavbg: "btn-nav-bg-white",
            navscr:"",
            dreamsModal: false,
            logoimg: "logo-dark-white.webp"
        };
    }
    toggleModal = modalState => {
        this.setState({
            [modalState]: !this.state[modalState]
        });
    };
    componentDidMount() {
        window.addEventListener("scroll", this.changeColor);
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.changeColor);
    }

    changeColor = () => {
        if (
            document.documentElement.scrollTop > 99 ||
            document.body.scrollTop > 99
        ) {
            this.setState({
                color: "bg-white",
                btntextcolor: "btn-nav-blue",
                btnnavbg: "btn-nav-bg-blue",
                navscr: "-scroll",
                logoimg: "logo-light-blue.webp"
            });
        } else if (
            document.documentElement.scrollTop < 100 ||
            document.body.scrollTop < 100
        ) {
            this.setState({
                color: "bg-transparent",
                btntextcolor: "btn-nav-white",
                btnnavbg: "btn-nav-bg-white",
                navscr: "",
                logoimg: "logo-dark-white.webp"
            });
        }
    };
    toggleCollapse = () => {
        document.documentElement.classList.toggle("nav-open");
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };
    onCollapseExiting = () => {
        this.setState({
            collapseOut: "collapsing-out"
        });
    };
    onCollapseExited = () => {
        this.setState({
            collapseOut: ""
        });
    };


    getPageScroll = () => {
        let yScroll;

        if (window.pageYOffset) {
            yScroll = window.pageYOffset;
        } else if (document.documentElement && document.documentElement.scrollTop) {
            yScroll = document.documentElement.scrollTop;
        } else if (document.body) {
            yScroll = document.body.scrollTop;
        }
        return yScroll;
    };

    scrollPage = (element) => {
        let targetOffset = element.offsetTop;
        let currentPosition = this.getPageScroll();
        let body = document.body;
        let animateTime = 600;
        body.classList.add('in-transition');
        if(targetOffset > currentPosition) {
            body.style.transform = "translate(0, -" + (targetOffset - 65 - currentPosition) + "px)";
        } else {
            body.style.transform = "translate(0, " + (-targetOffset + 65 + currentPosition) + "px)";
        }
        window.setTimeout(function () {
            body.classList.remove('in-transition');
            body.style.cssText = "";
            console.log(targetOffset);
            window.scrollTo(0, targetOffset - 65);
        }, animateTime);
    };

    scrollToMeble = () => {
        this.scrollPage(document.getElementById('meble'));
    };
    scrollToWykonczenie = () => {
        this.scrollPage(document.getElementById('wykonczenie'));
    };
    scrollToUslugi = () => {
        this.scrollPage(document.getElementById('uslugi'));

    };
    scrollToKontakt = () => {
        this.scrollPage(document.getElementById('kontakt'));
    };
    render() {
        return (
            <Navbar
                className={"fixed-top " + this.state.color}
                color-on-scroll="100"
                expand="lg"
            >
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand
                            to="/"
                            id="navbar-brand"
                            tag={Link}
                        >
                            <img className="brand-logo"
                                 src={require("assets/img/" + this.state.logoimg)}
                                 alt="Dreams are real" />
                            <span className={"text-dark"}/>{/*В спане можно ввести название, которое появится рядом с логотипом*/}
                        </NavbarBrand>
                        <div className={"links-mobile" + this.state.navscr}>
                            <NavLink
                                data-placement="bottom"
                                href="https://kb.pl/firmy/dreams-are-real-sp-z-o-o/"
                                rel="noopener noreferrer"
                                target="_blank"
                                title="kb.pl"
                            >
                                <i className={"kbpl" + this.state.navscr}  />
                            </NavLink>
                            <NavLink
                                data-placement="bottom"
                                href="https://dreamsarereal.oferteo.pl/"
                                rel="noopener noreferrer"
                                target="_blank"
                                title="oferteo.pl"
                            >
                                <i className={"oferteo" + this.state.navscr} />
                            </NavLink>
                            <NavLink
                                data-placement="bottom"
                                href="https://www.needhelp.com/pl-pl/user/693708"
                                rel="noopener noreferrer"
                                target="_blank"
                                title="needhelp.com"
                            >
                                <i className={"needhelp" + this.state.navscr} />
                            </NavLink>
                            <NavLink
                                className={"phone"}
                                data-placement={"bottom"}
                                href="tel:+48731493298"
                                title="+48731493298"
                            >
                                +48731493298
                            </NavLink>
                        </div>
                        <button
                            aria-expanded={this.state.collapseOpen}
                            className="navbar-toggler navbar-toggler"
                            onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <Collapse
                        className={"justify-content-center " + this.state.collapseOut}
                        navbar
                        isOpen={this.state.collapseOpen}
                        onExiting={this.onCollapseExiting}
                        onExited={this.onCollapseExited}
                    >
                        <div className="navbar-collapse-header">
                            <Row>
                                <Col className="collapse-brand" xs="6">
                                    <a href={"#dreamsarereal"} onClick={e => e.preventDefault()}>
                                        Dreams Are Real
                                    </a>
                                </Col>
                                <Col className="collapse-close text-right" xs="6">
                                    <button
                                        aria-expanded={this.state.collapseOpen}
                                        className="navbar-toggler"
                                        onClick={this.toggleCollapse}
                                    >
                                        <i className="tim-icons icon-simple-remove" />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <Nav navbar>
                            <NavItem>
                                <Button
                                    className={"btn-simple btn-round btn-navbar " + this.state.btntextcolor + " " + this.state.btnnavbg}
                                    type="button"
                                    onClick={this.scrollToMeble}
                                >
                                    &nbsp;Zabudowy meblowe&nbsp;
                                </Button>
                            </NavItem>
                            <NavItem>
                                <Button
                                    className={"btn-simple btn-round btn-navbar " + this.state.btntextcolor + " " + this.state.btnnavbg}
                                    type="button"
                                    onClick={this.scrollToWykonczenie}
                                >
                                    &nbsp;Wykończenie mieszkań&nbsp;
                                </Button>
                            </NavItem>
                            <NavItem>
                                <Button
                                    className={"btn-simple btn-round btn-navbar " + this.state.btntextcolor + " " + this.state.btnnavbg}
                                    type="button"
                                    onClick={this.scrollToUslugi}
                                >
                                    &nbsp;Usługi złotej rączki&nbsp;
                                </Button>
                            </NavItem>
                            <NavItem>
                                {/*<NavLink tag={Link}
                                         className="mr-1"
                                         onClick={() => {
                                             this.toggleModal("dreamsModal");
                                         }
                                         }
                                >
                                    Kontakt
                                </NavLink>*/}
                                <Button
                                    className={"btn-simple btn-round btn-navbar " + this.state.btntextcolor + " " + this.state.btnnavbg}
                                    type="button"
                                    onClick={this.scrollToKontakt}
                                >
                                    &nbsp;Kontakt&nbsp;
                                </Button>
                                <Modal
                                    modalClassName="modal-mini modal-info"
                                    isOpen={this.state.dreamsModal}
                                    toggle={() => this.toggleModal("dreamsModal")}
                                >
                                    <div className="modal-header justify-content-center">
                                        <button
                                            className="close"
                                            type="button"
                                            onClick={() => {
                                                this.toggleModal("dreamsModal");
                                            }
                                            }
                                        >
                                            <i className="tim-icons icon-simple-remove" style={{color:"white"}}/>
                                        </button>
                                        <h4>Kontakt</h4>
                                    </div>
                                    <ModalBody>
                                        <DreamsModal/>
                                    </ModalBody>
                                </Modal>
                            </NavItem>
                        </Nav>
                    </Collapse>
                    <div className={"phones"}>
                        <NavLink
                            data-placement={"bottom"}
                            href="tel:+48731493298"
                            title="+48731493298"
                        >
                            tel.: +48731493298
                        </NavLink>
                    </div>
                    <div className={"links" + this.state.navscr}>
                        <NavLink
                            className={"phone"}
                            data-placement={"bottom"}
                            href="tel:+48731493298"
                            title="+48731493298"
                        >
                            +48731493298
                        </NavLink>
                        <NavLink
                            data-placement="bottom"
                            href="https://kb.pl/firmy/dreams-are-real-sp-z-o-o/"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="kb.pl"
                        >
                            <i className={"kbpl" + this.state.navscr} />
                        </NavLink>
                        <NavLink
                            data-placement="bottom"
                            href="https://dreamsarereal.oferteo.pl/"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="oferteo.pl"
                        >
                            <i className={"oferteo" + this.state.navscr} />
                        </NavLink>
                        <NavLink
                            data-placement="bottom"
                            href="https://www.needhelp.com/pl-pl/user/693708"
                            rel="noopener noreferrer"
                            target="_blank"
                            title="needhelp.com"
                        >
                            <i className={"needhelp" + this.state.navscr} />
                        </NavLink>
                    </div>
                </Container>
            </Navbar>
        );
    }
}

export default DreamsNavbar2;
