import React from "react";

// reactstrap components
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import classnames from "classnames";
import * as emailjs from "emailjs-com/source/index";
class DreamsKontakt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 1,
            wyslij: true,
            label1: false,
            label2: false,
            active: true,
            feedback: '',
            name: '',
            email: '',
            tnumer: ''
        };
        this.handleFeedback = this.handleFeedback.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTnumer = this.handleTnumer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.label1f = this.label1f.bind(this);
        this.label2f = this.label2f.bind(this);
    }
    async label1f() {
        await this.setState({
            label1: !this.state.label1
        });
        await this.toggleButton();
    };
    async label2f(){
        await this.setState({
            label2: !this.state.label2
        });
        await this.toggleButton();
    };
    handleFeedback(event) {
        this.setState({feedback: event.target.value})
    }
    handleName(event) {
        this.setState({name: event.target.value})
    }
    handleEmail(event) {
        this.setState({email: event.target.value})
    }
    handleTnumer(event) {
        this.setState({tnumer: event.target.value})
    }
    sendFeedback (templateId, variables) {
        emailjs.init("user_Gib53zUo4CNVmPhxMqbFf");
        emailjs.send(
            'smtp_server', templateId,
            variables
        ).then(res => {
            console.log('Email successfully sent!')
        })
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
    async handleSubmit (event) {
        const templateId = 'template_m9NR2lqs';
        const currentState = this.state.active;
        await this.setState({
            active: !currentState
        });
        this.sendFeedback(templateId, {message_html: this.state.feedback, from_name: this.state.name, reply_to: this.state.email, phone_num: this.state.tnumer, marketing_agree: this.state.label2})
    }
    async toggleButton() {
        if(this.state.label1 === true) {
            await this.setState({
                wyslij: false
            });
        } else {
            await this.setState({
                wyslij: true
            });
        }
    };
    render() {
        return (
            <div className="section" id={"kontakt"}>
                <Container>
                    <Row className="justify-content-between">
                        <Col md="6" className={"align-self-center"}>
                            <Form className={"kontakt-form"} id={"kontakt-form"}>
                                <Alert className={this.state.active ? 'xshow alert-with-icon': 'alert-with-icon'} color={"success"} >
                                    <span data-notify="icon" className="tim-icons icon-bell-55" />
                                    <span>
                                    <b>Twój list został wyslany! -</b>
                                    Wkrótce skontaktujemy się z tobą.
                                </span>
                                </Alert>
                                <Row className="justify-content-between">
                                    <Col md={"6"}>
                                        <InputGroup
                                            className={classnames({
                                                "input-group-focus": this.state.inputFocus
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="tim-icons icon-pencil" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Imię"
                                                type="text"
                                                value={this.state.name}
                                                onChange = {this.handleName}
                                            />
                                        </InputGroup>
                                        <InputGroup
                                            className={classnames({
                                                "input-group-focus": this.state.inputFocus
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="tim-icons icon-email-85" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Email"
                                                type="text"
                                                value={this.state.email}
                                                onChange = {this.handleEmail}
                                            />
                                        </InputGroup>
                                        <InputGroup
                                            className={classnames({
                                                "input-group-focus": this.state.inputFocus
                                            })}
                                        >
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="tim-icons icon-mobile" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Nr. telefonu"
                                                type="text"
                                                value={this.state.tnumer}
                                                /*onFocus={e => this.setState({ inputFocus: true })}
                                                onBlur={e => this.setState({ inputFocus: false })}*/
                                                onChange = {this.handleTnumer}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col md={"6"}>
                                                <textarea
                                                    id="test-mailing"
                                                    name="test-mailing"
                                                    onChange={this.handleFeedback}
                                                    placeholder="Jestem zainteresowany ofertą. Proszę o kontakt"
                                                    required
                                                    value={this.state.feedback}
                                                    style={{width: '100%'}}
                                                />
                                    </Col>
                                </Row>
                                <Row className={"justify-content-between"}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" id={"checked-1"} onClick={this.label1f}/>
                                            <span className="form-check-sign" />
                                            Oświadczam, iż zapoznałem się z treścią regulaminu świadczenia usług drogą elektroniczną przez Spółkę DREAMS ARE REAL Sp. z o.o.
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" id={"checked-2"} onClick={this.label2f}/>
                                            <span className="form-check-sign" />
                                            Wyrażam zgodę na otrzymywanie informacji handlowych w tym marketingowych w odniesieniu do produktów własnych i usług drogą telefoniczną oraz elektroniczną
                                        </Label>
                                    </FormGroup>
                                    <div className="col-md-12 col-xs-12 col-sm-12 text scrolled">
                                        <p>Administratorem Pani/Pana Danych osobowych jest DREAMS ARE REAL Sp. Z o.o.
                                            z siedzibą w Warszawie, ul. Aleje Jerozolimskie 85, lok.21, 02–001 Warszawa. Spółka
                                            wpisana jest do rejestru przedsiębiorców Krajowego Rejestru Sądowego
                                            przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XII Wydział
                                            Gospodarczy KRS pod nr 0000837872.</p>
                                        <p>Informujemy, iż przetwarzamy Pani/Pana dane osobowe w&nbsp;celu
                                            obsługi korespondencji przesłanej za pośrednictwem formularza
                                            kontaktowego zamieszczonego na stronie internetowej; Podstawą
                                            prawną przetwarzania danych jest realizacja naszych prawnie
                                            uzasadnionych interesów (jako administratora danych osobowych)
                                            w&nbsp;postaci komunikacji z&nbsp;użytkownikami strony.
                                            W&nbsp;tym wypadku Pani/Pana dane będą przetwarzane nie dłużej,
                                            niż jest to konieczne do udzielenia odpowiedzi na zadane
                                            pytanie, a&nbsp;po tym czasie mogą być przetwarzane przez okres
                                            przedawnienia ewentualnych roszczeń.</p>
                                        <p>Ponadto podane przez Panią/Pana Dane osobowe będą przetwarzane
                                            w&nbsp;celach marketingowych i&nbsp;handlowych, a&nbsp;podstawą
                                            prawną przetwarzania jest, w&nbsp;przypadku jej wyrażenia przez
                                            Panią/Pana – zgoda. W&nbsp;tym wypadku Pani/Pana Dane osobowe
                                            będą przetwarzane do czasu odwołania zgody, którą cofnąć może
                                            Pani/Pan w&nbsp;każdym momencie.</p>
                                        <p>Informujemy, iż podanie przez Panią/Pana danych jest dobrowolne
                                            lecz konieczne do tego, żeby odpowiedzieć na zadane pytanie lub
                                            przesłanie oferty handlowej. Ma Pani/Pan prawo do żądania
                                            dostępu do swoich danych osobowych, ich sprostowania, usunięcia
                                            lub ograniczenia przetwarzania, a&nbsp;także prawo wniesienia
                                            sprzeciwu wobec przetwarzania, a&nbsp;także prawo do
                                            przenoszenia swoich danych oraz wniesienia skargi do organu
                                            nadzorczego. </p>
                                        <p>Informujemy, iż podane przez Panią/Pana dane osobowe nie będą
                                            udostępniane do przetwarzanie osobom trzecim,
                                            w&nbsp;szczególności państwom z&nbsp;wyjątkiem sytuacji gdy
                                            jesteśmy zobowiązani do ich przekazania na podstawie przepisów
                                            prawa.</p>
                                        <p>Wszystkie podane przez Panią/Pana dane są poufne i&nbsp;będę
                                            przetwarzane wyłącznie w&nbsp;celu podanym powyżej. Gdyby dane
                                            miały być przetwarzane w&nbsp;innym celu, powiadomimy o&nbsp;tym
                                            Pana/Panią zapewniając tym samym Pani/Panu możliwość wyrażenia
                                            braku zgody na przetwarzanie przez nas tych danych do innych
                                            celów niż pierwotny.
                                        </p></div>
                                    <Button className="btn-simple btn-round"
                                            id={"wyslij"}
                                            color="primary"
                                            type="button"
                                            disabled={this.state.wyslij}
                                            onClick={this.handleSubmit}
                                    >
                                        <i className="tim-icons icon-mobile" />
                                        &nbsp;&nbsp;Wyślij
                                    </Button>
                                </Row>


                            </Form>

                        </Col>
                        <Col md="5">
                            <h1 className="profile-title text-left text-blue">Skontaktuj się <b>z nami</b></h1>
                            <h5 className="text-on-back text-blue-opacity">04</h5>
                            <div>
                                <div className="icon icon-primary display-inline">
                                    <i className="tim-icons icon-mobile icon-rem-2" />
                                </div>
                                <div className="description-1 display-inline">
                                    <h4 className="info-title display-inline">Zadzwoń</h4>
                                    <p className={"description-2"}>
                                        +48731493298<br/>
                                        +48739681222
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="icon icon-primary display-inline">
                                    <i className="tim-icons icon-email-85 icon-rem-2" />
                                </div>
                                <div className="description-1 display-inline">
                                    <h4 className="info-title display-inline">Napisz</h4>
                                    <p className={"description-2"}>
                                        kontakt@dreamsarereal.pl
                                    </p>
                                </div>
                            </div>
                            <div>
                                <div className="icon icon-primary display-inline">
                                    <i className="tim-icons icon-watch-time icon-rem-2" />
                                </div>
                                <div className="description-1 display-inline">
                                    <h4 className="info-title display-inline">Godziny pracy</h4>
                                    <p className={"description-2"}>
                                        pon-pt 8:00-16:00
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default DreamsKontakt;