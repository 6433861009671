import React from "react";

// reactstrap components
import {Button, Card, Col, Container, Row, UncontrolledCarousel} from "reactstrap";
const mebleItems = [
    {
        src: require("assets/img/10.webp"),
        altText: "pokój1",
        caption: ""
    },
    {
        src: require("assets/img/6-4.webp"),
        altText: "pokój2",
        caption: ""
    },
    {
        src: require("assets/img/13.webp"),
        altText: "pokój3",
        caption: ""
    },
    {
        src: require("assets/img/14.webp"),
        altText: "pokój4",
        caption: ""
    }
];
class DreamsMeble extends React.Component {
    getPageScroll = () => {
        let yScroll;

        if (window.pageYOffset) {
            yScroll = window.pageYOffset;
        } else if (document.documentElement && document.documentElement.scrollTop) {
            yScroll = document.documentElement.scrollTop;
        } else if (document.body) {
            yScroll = document.body.scrollTop;
        }
        return yScroll;
    };

    scrollPage = (element) => {
        let targetOffset = element.offsetTop;
        let currentPosition = this.getPageScroll();
        let body = document.body;
        let animateTime = 600;
        body.classList.add('in-transition');
        if(targetOffset > currentPosition) {
            body.style.transform = "translate(0, -" + (targetOffset - 65 - currentPosition) + "px)";
        } else {
            body.style.transform = "translate(0, " + (-targetOffset + 65 + currentPosition) + "px)";
        }
        window.setTimeout(function () {
            body.classList.remove('in-transition');
            body.style.cssText = "";
            console.log(targetOffset);
            window.scrollTo(0, targetOffset - 65);
        }, animateTime);
    };
    scrollToKontakt = () => {
        this.scrollPage(document.getElementById('kontakt'));
    };
    render() {
        return (
            <Card id={"meble"}>
                <Container className="align-items-center">
                    <Row>
                        <Col lg="6" md="6">
                            <h1 className="profile-title text-left text-blue">Zabudowy meblowe <b>pod wymiar</b></h1>
                            <h5 className="text-on-back text-blue-opacity">01</h5>
                            <p className="profile-description">
                                Do każdego klienta podchodzimy bardzo osobiście. Zapewniamy kompleksową obsługę dotyczącą projektu instalacji elektrycznych dostosowanych do zabudowy, niepowtarzalny, indywidualny projekt mebla, dobór materiałów, skończywszy na profesjonalnym montażu. <br/><br/>
                                Korzystając z naszych usług oszczędzasz czas nie musząc korzystać z kilku podmiotów. Udowadniamy, że potrzeby naszych klientów są dla nas najważniejsze.
                            </p>
                            <div className="btn-wrapper profile pt-3">
                                <Button className="btn-simple btn-round"
                                        color="primary"
                                        type="button"
                                        onClick={this.scrollToKontakt}
                                >
                                    <i className="tim-icons icon-mobile" />
                                    &nbsp;&nbsp;Umów się z nami na spotkanie
                                </Button>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto mt-30" lg="6" md="6" style={{height:"500px"}}>
                            <UncontrolledCarousel items={mebleItems} />
                        </Col>
                    </Row>
                </Container>
            </Card>
        );
    }
}

export default DreamsMeble;
